import SockJS from 'sockjs-client'
import Stomp from 'stompjs'
import store from '@/store'
import order from '@/apis/order'
import router from '@/router'
import { emitter } from '@/utils/mitt'
import { alert as $alert } from '../../utils/alert'

export default {
	namespaced: true,
	// modules: {}
	state: {
		socket: null,
		stompClient: null,
		heartbeatTimer: null, // 心跳計時器
		errorTimeout: null, // 斷線重連計時器
		isConnected: false,
		onWsWatchOrder: null, // {orderStatusId: number, orderId: number}
		errorCount: 0, // 斷線重連次數
	},
	mutations: {
		setOnWsWatchOrder($, id) {
			$.onWsWatchOrder = id
		},
	},
	actions: {
		initWebSocket(ctx) {
			const { dispatch, state } = ctx
			state.isConnected = true
			dispatch('connection')

			if (state.heartbeatTimer) {
				clearInterval(state.heartbeatTimer)
				state.heartbeatTimer = null
			}
			state.heartbeatTimer = setInterval(() => {
				try {
					state.stompClient.send('/app/connect/shop', {}, 'PING')
				} catch (err) {
					dispatch('reconnection')
				}
			}, 10000)
		},
		connection(ctx) {
			const { state, dispatch } = ctx
			if (!state.isConnected) {
				return
			}
			if (state.socket == null && state.stompClient == null) {
				state.socket = new SockJS(
					`${process.env.VUE_APP_BASE_API}/app/websocket`,
				)
				state.stompClient = Stomp.over(state.socket)
				state.stompClient.debug = null
			}

			state.stompClient.connect(
				{
					account: store.state.user.guestId,
					store: store.state.user.storeId,
					buyerType: store.getters.isKiosk ? 'USER' : 'GUEST',
				},
				() => {
					dispatch('successCallback')
				},
				err => {
					console.error(err)
					dispatch('onErrorReConnection')
				},
			)
		},
		successCallback(ctx) {
			const { state } = ctx
			state.errorCount = 0
			if (state.errorTimeout) {
				clearInterval(state.errorTimeout)
				state.errorTimeout = null
			}
			$devLog('%c連接成功', 'color:green;background-color:#fff;')
			if (!store.getters.isKiosk) {
				state.stompClient.subscribe('/user/store', ({ body }) => {
					const JSONBody = JSON.parse(body)
					$devLog('/user/store', JSONBody)
					store.commit('takeMeal/SET_TAKE_NUMBER', JSONBody.maxTakeNumber)
					store.commit(
						'takeMeal/SET_TAKE_NUMBER_LIST',
						JSONBody.canTakeNumberList,
					)
				})
			}
			state.stompClient.subscribe('/user/subscribe', ({ body }) => {
				const JSONBody = JSON.parse(body)
				const { paidStatus, id, status, storeId } = JSONBody
				if (store.getters.isKiosk || !storeId) {
					return
				}

				$devLog('/user/subscribe', JSONBody)
				const save = {
					orderId: id,
					orderStatus: status,
				}

				if (router.history.current.name === 'TakeMeal')
					emitter.emit('TakeMeal_PAID', { id, paidStatus })

				if (router.history.current.name !== 'TakeMeal' && status === 3) {
					router.push(`/${router.history.current.params.random}/TakeMeal/${id}`)
				} else if (status === 5 || status === 6) {
					store.state.isCancelOrderDialog = true
					ctx.commit('setOnWsWatchOrder', save)
				} else {
					ctx.commit('setOnWsWatchOrder', save)
				}
			})
			// 訂閱刷新通道
			if (store.state.user.storeId) {
				state.stompClient.subscribe(
					`/refresh/${store.state.user.storeId}`,
					async msg => {
						const data = JSON.parse(msg.body)
						$devLog('/refresh', data)
						if (store.state.user.storeId !== data.storeId) {
							$devLog('店家id不合')
							return
						}
						if (data.type === 'SETTING') {
							$devLog('刷新店家設定')
							ctx.commit('store/SET_REFRESH_SETTING', true, { root: true })
						}

						if (data.type === 'MENU') {
							// 當前在pos頁才刷新
							if (router.history.current.name === 'Store') {
								$devLog('刷新菜單')
								ctx.commit('store/SET_REFRESH_MENU', true, { root: true })
							}
						}

						if (data.type === 'AUTOMATIC_DISCOUNT') {
							// 需要重取資料，標記為未就緒
							ctx.commit('store/SET_AUTOMATIC_DISCOUNT_LIST_READY', false, {
								root: true,
							})
							ctx.commit('store/SET_REFRESH_AUTOMATIC_DISCOUNT', true, {
								root: true,
							})
						}
					},
				)
			}
			// 訂閱平台公告通道
			state.stompClient.subscribe(`/announcement/platform`, async msg => {
				const data = JSON.parse(msg.body)
				$devLog('/announcement/platform', data)
				if (data.event === 'START_MAINTENANCE') {
					ctx.commit(
						'setMaintenance',
						{
							modal: true,
							title: data.title,
							message: data.message,
						},
						{ root: true },
					)
					return
				}

				if (data.event === 'END_MAINTENANCE') {
					ctx.commit(
						'setMaintenance',
						{
							modal: false,
							title: '',
							message: '',
						},
						{ root: true },
					)
				}
			})
		},
		onErrorReConnection(ctx) {
			const { dispatch, state, rootState } = ctx
			if (rootState.maintenance.modal) {
				state.errorCount = 1
			}
			if (state.heartbeatTimer) {
				clearInterval(state.heartbeatTimer)
				state.heartbeatTimer = null
			}

			state.isConnected = false
			if (state.errorCount < 15) {
				state.errorCount++
				const now = new Date()
				const formattedTime = new Intl.DateTimeFormat('zh-TW', {
					hour: '2-digit',
					minute: '2-digit',
					second: '2-digit',
					hour12: false,
				}).format(now)

				$devLog(
					`%c斷線續連嘗試次數 ${state.errorCount} - 當前時間: ${formattedTime}`,
					'color:red;background-color:#fff;',
				)

				if (state.errorTimeout) {
					clearTimeout(state.errorTimeout)
					state.errorTimeout = null
				}
				if (state.errorCount > 2) {
					$alert('您的網路不太穩定!', 'danger')
				}
				state.errorTimeout = setTimeout(() => dispatch('reconnection'), 500)
				return
			}

			$devLog(
				`%c多次重連失敗，重新整理頁面`,
				'color:red;background-color:#fff;',
			)
			this.dispatch('disconnect')
			state.errorCount = 0
			window.location.reload()
		},
		reconnection(ctx) {
			const { dispatch } = ctx
			dispatch('disconnect')
			dispatch('initWebSocket')
		},
		async disconnect(ctx) {
			const { state } = ctx
			state.isConnected = false
			if (state.stompClient !== null) {
				clearInterval(state.heartbeatTimer)
				clearTimeout(state.errorTimeout)
				await state.stompClient.disconnect()
				state.socket = null
				state.stompClient = null
			}
		},
		async addOrder(ctx, data) {
			return await order.addOrder(data)
		},
	},
}
