const getters = {
	storeName: state => state.user.storeName,
	isGuest: state => state.user.userId == null,
	isKiosk: state => state.kiosk.is, // 是否為前台點餐
	isOpen: state => {
		const { openInfo } = state.store
		if (!openInfo) return false
		if (openInfo.open) return true
		return false
	},
	isBooking: state => {
		const { openInfo } = state.store
		if (!openInfo) return false
		return !!openInfo.bookingSwitch
	},
	isOnsiteTake: state => {
		const { openInfo } = state.store
		if (!openInfo) return false
		return !!openInfo.onsiteTakeSwitch
	},
	isMobileOrder: state => {
		const { openInfo } = state.store
		return !!openInfo.mobileOrderSwitch
	},
	canBuy: state => {
		const { openInfo } = state.store
		if (state.kiosk.is) return true
		if (!openInfo) return false
		return !!(
			(openInfo.open || openInfo.bookingSwitch) &&
			openInfo.mobileOrderSwitch
		)
	},
}
export default getters
