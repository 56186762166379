import request from './axios'
import order from './order'
import qrCode from './qrCode'

const list = {
	user: {
		// 一般帳號登入
		login(params) {
			return request.post(`/front/login`, params)
		},
		// 新增一般使用者
		register(params) {
			return request.put(`/register`, params)
		},
		// 忘記密碼
		findByPhone(params) {
			return request.get(`/findByPhone`, { params })
		},
		// 修改密碼
		updatePassword(params) {
			return request.put(`/updatePassword`, params)
		},
		// 取得使用者資訊
		getUserDetail() {
			return request.get(`/login/getUserDetail`)
		},
		// 修改會員資料
		updateUserDetail(params) {
			return request.post(`/user/updateUserDetail`, params)
		},
		//發送簡訊碼
		sendMessage(params) {
			return request.post(`/message/send`, params)
		},
		//驗證簡訊碼
		verifyMessage(params) {
			return request.post(`/message/verify`, params)
		},
		// Google第三方登入
		googleLogin(params) {
			return request.post(`/googleLogin`, params)
		},
		// Google第三方綁定
		googleBinding(params) {
			return request.put(`/googleBinding`, params)
		},
		// FB第三方登入
		fbLogin(params) {
			return request.post(`/fbLogin`, params)
		},
		// FB第三方綁定
		fbBinding(params) {
			return request.put(`/fbBinding`, params)
		},
		// 登入/註冊
		getGuestId() {
			return request.get(`/front/guestLogin`)
		},
		// 取得訪客資訊
		getCurrentBuyer() {
			return request.get(`/front/getCurrentBuyer`)
		},
		//登出
		logout() {
			return request.post(`/logout`)
		},
	},
	order,
	qrCode,

	//  店家營業資訊
	getOpenInfo: id => request.get('front/store/openInfo?storeId=' + id),

	//取得聯絡我們表單網址
	getUrl: () => request.get('front/contactus/getUrl'),

	// 取得啟用中的自動折扣
	getActiveAutomaticDiscountList: id =>
		request.get('front/store/getActiveAutomaticDiscountList?storeId=' + id),
}

export default Vue => {
	Vue.prototype.$api = list
}
