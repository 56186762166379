import './styles/index.sass'
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vtime from './utils/vtime'
import components from 'byod'
import apis from './apis'
import alert from './utils/alert.js'
import VueQrcode from '@chenfengyuan/vue-qrcode'
import './utils/style.sass'
import './directives/scroll.js'
import './directives/click-outside.js'
import {
	countOrderPrice,
	countProductPrice,
	getOrderTotal,
} from '@/utils/order'
import { stepPrice } from '@/utils/stepPrice'
import 'regenerator-runtime/runtime'
import VueHtml2Canvas from 'vue-html2canvas'
import VueMeta from 'vue-meta'

Vue.config.productionTip = false
Vue.prototype.$countProductPrice = countProductPrice
Vue.prototype.$countOrderPrice = countOrderPrice
Vue.prototype.$getOrderTotal = getOrderTotal
Vue.prototype.$stepPrice = stepPrice
Vue.prototype.$vtime = vtime
Vue.prototype.$env = process.env
Vue.use(alert)
Vue.use(components)
Vue.use(apis)
Vue.use(VueHtml2Canvas)
Vue.component(VueQrcode.name, VueQrcode)
Vue.use(VueMeta)

window.$devLog = (...args) => {
	if (process.env.NODE_ENV === 'development') {
		const log = console[args[0]]
		if (log) {
			log(...args.slice(1))
		} else {
			console.log(...args)
		}
	}
}

window.$jsonLog = (...args) => {
	if (process.env.NODE_ENV === 'development') {
		const formattedArgs = args.map(arg =>
			typeof arg === 'object' ? JSON.stringify(arg, null, 2) : arg,
		)

		const log = console[formattedArgs[0]]
		if (log) {
			log(...formattedArgs.slice(1))
		} else {
			console.log(...formattedArgs)
		}
	}
}

router.beforeEach((to, from, next) => {
	if (location.hash.startsWith('#/')) {
		location.replace(location.pathname + location.hash.slice(2))
	}

	if (
		to.fullPath === `/${to.params.random}/Order` ||
		to.fullPath === `/${to.params.random}/Cart` ||
		to.fullPath.startsWith(`/${to.params.random}/Detail/${to.params.id}`)
	) {
		if (!store.getters.canBuy) {
			next(`/${to.params.random}/Store`)
			if (to.meta.title) {
				document.title = to.meta.title
			}
		} else {
			next()
		}
	} else if (to.name === `Main`) {
		next(`/${to.params.random}/Store`)
	} else if (from.fullPath === '/Verify' || from.fullPath === '/Login') {
		next()
	} else {
		next()
	}
})

new Vue({
	router,
	store,
	render: h => h(App),
}).$mount('#app')
