<template>
	<div id="app">
		<router-view />

		<ByDialog
			class="clear-cart-confirm"
			:value="isCancelOrderDialog"
			width="320px"
			title="您的餐點已被取消"
			@closeDialog="onCloseCancelOrderDialog"
		>
			<template slot="dialog-footer">
				<div class="dialog-footer">
					<ByButton stl="main1" @click="onCloseCancelOrderDialog"
						>好的
					</ByButton>
				</div>
			</template>
		</ByDialog>

		<!--	網頁操作預期的彈窗	-->
		<ByDialog
			class="clear-cart-confirm"
			:value="operationHasFailed"
			width="560px"
			title="網頁操作逾期"
			@closeDialog="onCloseOperationHasFailedDialog"
		>
			<div>請刷新頁面後重新進行點餐</div>
			<span
				>網頁將在
				<span class="refresh-count-down"> {{ refreshCountDown }} </span>
				秒後自動重新整理...
			</span>

			<template slot="dialog-footer">
				<div class="dialog-footer">
					<ByButton stl="main1" @click="onCloseOperationHasFailedDialog"
						>立即重新整理
					</ByButton>
				</div>
			</template>
		</ByDialog>
	</div>
</template>
<script>
import ByDialog from '@/components/Dialog.vue'
import ByButton from '@/components/Button.vue'

export default {
	components: { ByButton, ByDialog },
	data() {
		return {
			getContactLoad: true,
			getWebSocketLoad: true,
			pageTitle: '點溡線上點餐',
			refreshCountDown: 10,
			refreshInterval: null,
			initAutomaticDiscount: true,
		}
	},
	metaInfo() {
		return {
			title: this.pageTitle,
			meta: [
				{
					property: 'og:title',
					content: this.pageTitle,
					'data-react-helmet': true,
				},
			],
		}
	},
	computed: {
		isGuest() {
			return this.$store.state.user?.guestId
		},
		isCancelOrderDialog() {
			return this.$store.state.isCancelOrderDialog
		},
		storeId() {
			return this.$store.state.user.storeId
		},
		operationHasFailed() {
			return this.$store.state.user.operationHasFailed
		},
		// 是否前台點餐
		isKiosk() {
			return this.$store.getters.isKiosk
		},
		cart() {
			return this.$store.getters['user/currCart']
		},
		cartTotal() {
			return this.$store.getters['user/cartTotal']
		},
		itemAutomaticDiscountList() {
			return this.$store.state.store.itemAutomaticDiscountList
		},
		orderAutomaticDiscountList() {
			return this.$store.state.store.orderAutomaticDiscountList
		},
		automaticDiscountListReady() {
			return this.$store.state.store.automaticDiscountListReady
		},
		refreshAutomaticDiscount() {
			return this.$store.state.store.refreshAutomaticDiscount
		},
		useOrderAutomaticDiscount() {
			return this.$store.state.user.useOrderAutomaticDiscount
		},
		cartHasFreeItem() {
			return this.$store.getters['user/currCartHasFreeItem']
		},
	},
	watch: {
		async '$route.params.random'(e, v) {
			const kiosk = this.$route.query.kiosk
			if (kiosk && kiosk === e) {
				await this.getStore()
				return
			}
			if (e && e !== v) {
				await this.getStore()
				await this.checkBuyer()
			}
			if (!!!v) {
				// 首次進入
				if (this.$store.state.uri === null) {
					this.goContact()
				}

				this.initWebSocket()
				if (!this.$store.getters.isKiosk) {
					await this.getOpenInfo()
					this.checkWhatsEatBinding()
				}
			}
		},
		async '$route.path'(e, v) {
			if (e && e !== v && v !== '/') {
				if (this.$route.meta.requiredStore && !this.$store.getters.isKiosk) {
					if (this.storeId && this.isGuest) {
						await this.getOpenInfo()
					}
				}
			}
		},
		async '$store.state.store.refreshSetting'(val, beforeVal) {
			if (val) {
				await this.getOpenInfo()
				this.$store.commit('store/SET_REFRESH_SETTING', false)
				this.checkWhatsEatBinding()
			}
		},
		operationHasFailed(val, beforeVal) {
			this.refreshInterval = window.setInterval(() => {
				if (this.refreshCountDown > 0) {
					this.refreshCountDown -= 1 //
				} else {
					window.clearInterval(this.refreshInterval)
					this.$store.commit('user/SET_OPERATION_HAS_FAILED', false)
					this.refresh()
				}
			}, 1000)
		},
		cartTotal(val, beforeVale) {
			if (this.automaticDiscountListReady) {
				this.$store.commit('user/SET_CURRENT_ORDER_AUTOMATIC_DISCOUNT', {
					cartTotal: val,
					list: this.orderAutomaticDiscountList,
				})
			}
		},
		// 自動折扣刷新
		async refreshAutomaticDiscount(val, beforeVale) {
			if (val) {
				await this.getActiveAutomaticDiscountList()

				// 清除目前訂單折扣
				this.$store.commit('user/CLEAR_CURRENT_ORDER_AUTOMATIC_DISCOUNT')

				if (this.cart.length > 0) {
					// 重新計算商品折扣
					this.cartItemSetAutomaticDiscount()
					// 重新計算訂單折扣
					this.$store.commit('user/SET_CURRENT_ORDER_AUTOMATIC_DISCOUNT', {
						cartTotal: this.cartTotal,
						list: this.$store.state.store.orderAutomaticDiscountList,
					})
				}

				const alertText =
					'店家更新了商品折扣，菜單' +
					(this.cart.length ? '與購物車商品' : '') +
					'已自動更新'
				this.$alert(alertText)

				this.$store.commit('store/SET_REFRESH_AUTOMATIC_DISCOUNT', false)
			}
		},
		// 套用的訂單自動折扣
		useOrderAutomaticDiscount(val, beforeVale) {
			// 除了初始化時，當訂單折扣變更時自動刪除物品
			if (!this.initAutomaticDiscount && val?.id !== beforeVale?.id) {
				if (beforeVale?.discountType === 'FREE_ITEM' && this.cartHasFreeItem) {
					const freeItemIndex = this.cart.findIndex(item => item.freeItem)
					if (freeItemIndex !== -1) {
						this.$store.commit('user/REMOVE_CART_ITEM', {
							index: freeItemIndex,
						})
					}
				}
			}
		},
	},
	async created() {
		this.$store.commit('user/SET_STORE', { storeId: null, storeName: '' })
		document.title = '點溡點餐平台'
		this.goContact()
		const qrCodeHash = this.$route.params.random
		if (qrCodeHash) {
			await this.getStore()
			if (this.$store.state.maintenance.modal) {
				return
			}
			const kiosk = this.$route.query.kiosk === qrCodeHash
			if (!kiosk) {
				await this.checkBuyer()
				if (!this.$store.getters.isKiosk) {
					this.initWebSocket()
					await this.getOpenInfo()
				}
			}
		}
	},
	methods: {
		// 聯絡點溡
		goContact() {
			this.$api.getUrl().then(res => {
				if (res.data) {
					this.$store.commit('store/SET_URI', res.data)
				}
			})
		},
		// 辨別店家id
		async getStore() {
			const qrCodeHash = this.$route.params.random
			const loginType = this.$route.query.kiosk ? 'FRONT' : 'GUEST'

			await this.$api.qrCode
				.getStore({ qrCodeHash, loginType })
				.then(async res => {
					if (res.status) {
						const storeName = res.data.name
						this.$store.commit('user/SET_STORE', {
							storeId: res.data.id,
							storeName: storeName,
						})
						document.title = storeName ? storeName : '點溡點餐平台'
						this.pageTitle = storeName
							? storeName + ' | 線上點餐'
							: '點溡點餐平台'
						this.pageTitle = storeName
							? storeName + ' | 線上點餐'
							: '點溡點餐平台'

						this.$store.commit('user/CHECK_CART_AND_SET')

						// 清除舊訂單折扣
						this.$store.commit('user/CLEAR_CURRENT_ORDER_AUTOMATIC_DISCOUNT')

						await this.getActiveAutomaticDiscountList()

						// 如果購物車有先前存放的商品，重新計算購物車商品自動折扣金額
						if (this.cart.length > 0) {
							this.cartItemSetAutomaticDiscount()
						}

						if (this.automaticDiscountListReady) {
							this.$store.commit('user/SET_CURRENT_ORDER_AUTOMATIC_DISCOUNT', {
								cartTotal: this.cartTotal,
								list: this.orderAutomaticDiscountList,
							})
						}
						this.initAutomaticDiscount = false
						if (this.cartHasFreeItem) {
							// freeItem要等到 商品折扣設置完 => 訂單折扣設置 完後才能檢查
							this.checkFreeItem()
						}
					} else {
						this.handleGetStoreIdFail(res.message)
					}
				})
				.catch(err => {
					if (err === '服務更新中') {
						return
					}
					this.handleGetStoreIdFail(err)
				})
		},
		handleGetStoreIdFail(message) {
			this.$alert(message, 'danger')
			this.$store.commit('user/SET_STORE', { storeId: null, storeName: '' })
			document.title = '點溡點餐平台'
			this.$router.replace('/null/Error')
		},
		async checkBuyer() {
			if (
				this.$store.getters.isKiosk ||
				this.$route.path === `/${this.$route.params.random}/Refresh`
			) {
				await this.handleUserBuyer()
			} else {
				await this.handleGuestBuyer()
			}
		},
		async handleUserBuyer() {
			const userLogin = await this.$api.user.getCurrentBuyer()
			const { errorCode, data } = userLogin
			if (errorCode === 'SUCCESS') {
				if (data.loginType === 'FRONT') {
					if (this.judgeHasStorePermission(data)) {
						this.$store.commit('kiosk/SET_IS', true)

						if (this.$route.path !== `/${this.$route.params.random}/Store`) {
							await this.$router.replace(`/${this.$route.params.random}/Store`)
						}
						return
					}
				}
			}

			await this.$api.user.logout()
			this.$store.commit('kiosk/SET_IS', false)
			this.$store.commit('user/SET_GUEST_ID', null)
			this.$store.commit('user/SET_USER_NAME', '')
			this.$store.commit('user/SET_USER_PHONE', '')
			this.$store.commit('user/SET_USER_ADDRESS', '')
			this.$store.commit('user/SET_XSRF_TOKEN', '')
			await this.$router.replace(
				`/${this.$route.params.random}/Refresh?kiosk=${this.$route.params.random}`,
			)
		},
		judgeHasStorePermission(data) {
			const { permissionList, storeList } = data
			if (
				permissionList.includes('MANAGER') ||
				permissionList.includes('SUPER_MANAGER')
			) {
				return true
			}
			return storeList.some(store => store.id === this.storeId)
		},
		async handleGuestBuyer() {
			try {
				const response = await this.$api.user.getGuestId()
				this.$store.commit('kiosk/SET_IS', false)
				this.$store.commit('user/SET_GUEST_ID', response.data.account)
				this.$store.commit('user/SET_XSRF_TOKEN', response.xsrfToken)
			} catch (err) {
				this.$store.commit('kiosk/SET_IS', false)
				this.$store.commit('user/SET_GUEST_ID', null)
				this.$store.commit('user/SET_XSRF_TOKEN', '')
				if (this.$store.state.maintenance.modal) {
					return
				}
				location.reload()
			}
		},
		getOpenInfo() {
			if (this.storeId == null) return
			return this.$api.getOpenInfo(this.storeId).then(res => {
				this.$store.commit('store/SET_TAKE_OPEN_INFO', res.data)
			})
		},
		initWebSocket() {
			if (
				this.storeId == null ||
				!this.isGuest ||
				this.$store.state.socket.socket != null
			)
				return
			this.$store.dispatch('socket/initWebSocket')
		},
		onCloseCancelOrderDialog() {
			this.$store.state.isCancelOrderDialog = false
		},
		// 檢查WhatsEat店家綁定狀態與優惠卷
		checkWhatsEatBinding() {
			if (
				!this.$store.state.user.showWhatsEatCoupon ||
				!this.$store.state.store.openInfo?.whatsEatSwitch
			) {
				// 如果使用者關閉提示，就直接不取資料
				return
			}
			return this.$whatsEatApi
				.checkBinding(this.storeId)
				.then(res => {
					if (res.status === 200) {
						const count = res.data.data.availableTickets?.length || 0
						const shopId = res.data.data.binding.shopId
						this.$store.commit('store/SET_WHATS_EAT_SHOP_ID', shopId)
						this.$store.commit('store/SET_WHATS_EAT_COUPON_AMOUNT', count)
					} else {
						this.$store.commit('store/SET_WHATS_EAT_SHOP_ID', false)
						this.$store.commit('store/SET_WHATS_EAT_COUPON_AMOUNT', null)
					}
				})
				.catch(() => {
					// 若未綁定則返回404，清空WhatsEat相關狀態變數
					this.$store.commit('store/SET_WHATS_EAT_SHOP_ID', false)
					this.$store.commit('store/SET_WHATS_EAT_COUPON_AMOUNT', null)
				})
		},
		refresh() {
			return window.location.reload()
		},
		onCloseOperationHasFailedDialog() {
			this.$store.commit('user/SET_OPERATION_HAS_FAILED', false)
			this.refresh()
		},
		// 取得啟用中的自動折扣，放入全域狀態
		getActiveAutomaticDiscountList() {
			// 過濾前台或手機點餐適用
			const filter = this.isKiosk ? 'forFrontOrder' : 'forMobileOrder'
			return this.$api
				.getActiveAutomaticDiscountList(this.storeId)
				.then(res => {
					if (res.status && res.data.length) {
						const forOrderList = []
						const forItemList = []
						res.data.forEach(automaticDiscount => {
							if (
								automaticDiscount.discountMethod === 'ITEM_SPECIFIC' &&
								automaticDiscount[filter]
							) {
								forItemList.push(automaticDiscount)
							} else if (
								automaticDiscount.discountMethod === 'MINIMUM_SPENDING' &&
								automaticDiscount[filter]
							) {
								forOrderList.push(automaticDiscount)
							}
						})

						this.$store.commit(
							'store/SET_ITEM_AUTOMATIC_DISCOUNT_LIST',
							forItemList,
						)

						this.$store.commit(
							'store/SET_ORDER_AUTOMATIC_DISCOUNT_LIST',
							forOrderList,
						)
					} else if (res.data.length === 0) {
						// 當店家目前開啟折扣列表為空時，清除全域訂單與商品折扣
						this.$store.commit('store/SET_ITEM_AUTOMATIC_DISCOUNT_LIST', [])

						this.$store.commit('store/SET_ORDER_AUTOMATIC_DISCOUNT_LIST', [])
					}

					// 標記自動折扣列表就緒
					this.$store.commit('store/SET_AUTOMATIC_DISCOUNT_LIST_READY', true)
				})
				.catch(err => {
					// 取得折扣錯誤時，清除全域訂單與商品折扣
					this.$store.commit('store/SET_ITEM_AUTOMATIC_DISCOUNT_LIST', [])
					this.$store.commit('store/SET_ORDER_AUTOMATIC_DISCOUNT_LIST', [])
					this.$alert('取得自動折扣錯誤 : ' + err, 'danger')
				})
		},
		// 購物車商品設置折扣
		cartItemSetAutomaticDiscount() {
			this.cart.forEach(item => {
				this.addAutomaticDiscountToItem(item)
			})
		},
		// 檢查商品是否可用自動折扣 插入自動折扣的金額或折數以及automaticDiscountItemId
		addAutomaticDiscountToItem(item) {
			if (item.freeItem) {
				// freeItem因為會牽扯到訂單折扣，但訂單折扣又必須先計算商品折扣才被設置，所以freeItem一律先保留
				return
			}

			let discountItemId
			const itemAutomaticDiscount = this.itemAutomaticDiscountList.find(
				automaticDiscount => {
					return automaticDiscount.automaticDiscountItemList.find(
						discountItem => {
							discountItemId = discountItem.id
							return discountItem.itemId === item.itemId
						},
					)
				},
			)

			if (!itemAutomaticDiscount) {
				// 找不到自動折扣或已被刪除 消除折扣資訊
				item.automaticDiscountId = null
				item.automaticDiscountName = null
				item.automaticDiscountItemId = null
				item.discountPrice = null

				return
			}

			item.automaticDiscountId = itemAutomaticDiscount.id
			item.automaticDiscountItemId = discountItemId
			item.automaticDiscountName = itemAutomaticDiscount.name
			if (itemAutomaticDiscount.discountType === 'AMOUNT') {
				item.discountPrice = itemAutomaticDiscount.discountAmount
			} else if (itemAutomaticDiscount.discountType === 'PERCENTAGE') {
				// 避免浮點誤差
				item.discountPrice =
					item.itemPrice -
					item.itemPrice *
						(0.01 * itemAutomaticDiscount.discountPercentage).toFixed(2)
			} else if (itemAutomaticDiscount.discountType === 'FREE_ITEM') {
				console.log('未知的折扣類型', itemAutomaticDiscount)
			}
		},
		checkFreeItem() {
			// 找出freeItem
			const cartFreeItem = this.cart.find(item => item.freeItem)
			// 比對變更
			if (
				cartFreeItem.itemId !==
				this.useOrderAutomaticDiscount?.automaticDiscountItemList[0].itemId
			) {
				cartFreeItem.freeItem = false
				cartFreeItem.automaticDiscountId = null
				cartFreeItem.automaticDiscountName = null
				cartFreeItem.automaticDiscountItemId = null
				cartFreeItem.discountPrice = null
			}
		},
	},
}
</script>

<style lang="sass" scoped>
.refresh-count-down
	font-size: 22px
	color: #e83939
</style>
